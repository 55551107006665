<template>
  <div>
    <h2>{{ $t("views.tenants.users.title") }}</h2>

    <usertenant-relation-table
      :entityKey="eCurrent.key"
      :entityIdKey="eCurrent.idKey"
      :relationKey="relationKey"
      :tableSettings="table.default"
      :readOnly="isReadOnly"
    >
      <template #title>{{ $t("views.tenants.users.relationtable.title.default") }}</template>
      <template #killButton>{{ $t("views.tenants.users.relationtable.button.kill") }}</template>

      <template #modalTitle>{{ $t("views.tenants.users.relationtable.modal.title") }}</template>
      <template #modalBody="data">
        <i18n :path="`views.tenants.users.relationtable.modal.body.${data.type}`" tag="p">
          <template #username
            ><b-badge variant="info">{{ data.item.email }}</b-badge></template
          >
          <template #tenant
            ><b-badge variant="light">{{ parent.name }}</b-badge></template
          >
        </i18n>
      </template>
      <template #modalAbortButton>{{ $t("views.tenants.users.relationtable.modal.button.abort") }}</template>
      <template #modalConfirmButton="data">{{ $t(`views.tenants.users.relationtable.modal.button.confirm.${data.type}`) }}</template>
    </usertenant-relation-table>

    <div class="module" v-if="!isReadOnly">
      <h3 class="module-title">{{ $t("views.tenants.users.invite.title") }}</h3>
      <usertenant-invite-form :parentId="eParent.id" :entityKey="entityKey" :relationKey="relationKey" />
    </div>
  </div>
</template>

<script>
import { CLASS_TABLE_FIELD_KEY_ALL } from "@/constants";

import { TableHelper } from "@/assets/js/helper/table";

import MixinEntityBase from "@/components/mixins/EntityBase";
import UsertenantRelationTable from "@/components/entity/UsertenantRelationTable";

import UsertenantInviteForm from "@/components/entity/UsertenantInviteForm";

const ENTITY_KEY = "users";
const RELATION_KEY = "usertenants";

export default {
  name: "Tenant.Users",
  mixins: [MixinEntityBase],
  components: {
    UsertenantRelationTable,
    UsertenantInviteForm,
  },
  data() {
    return {
      entityKey: ENTITY_KEY,
      relationKey: RELATION_KEY,
      table: {
        default: {
          id: "Default",
          helper: TableHelper,
          filter: (e) => true,
          mapper: this.tableEntitiesMapper,
          search: {
            on: ["email"],
            placeholder: this.$t("views.tenants.users.relationtable.search.placeholder"),
          },
          options: {
            fields: {
              includes: ["$kill"],
              excludes: [],
              sorting: ["email", "permission", "$kill"],
              sortable: [CLASS_TABLE_FIELD_KEY_ALL],
              label: {
                $kill: "",
              },
              variant: {},
              class: {
                $kill: "cell-min cell-align-right",
              },
              thClass: {},
              tdClass: {},
            },
          },
          events: {
            rowClicked: this.tableRowClicked,
          },
        },
      },
    };
  },
  computed: {
    parent() {
      return this.$store.getters[`${this.eParent.key}/getEntityUnwrapped`](this.eParent.id);
    },
    isReadOnly() {
      return this.$store.getters["profile/isReadOnlyTenant"](this.eParent.id);
    },
  },
  methods: {
    getEntities() {
      this.$store.dispatch(`${this.relationKey}/getAll`, { by: this.eParent });
    },
    getUserDefinition() {
      this.$store.dispatch(`${this.entityKey}/getDefinition`);
    },
    tableEntitiesMapper(entities, definition) {
      return entities.map((e) =>
        Object.assign(
          e,
          {
            $routerLink: {
              name: "Users.Detail",
              params: {
                [this.eCurrent.paramKey]: e.id,
              },
            },
          },
          e
        )
      );
    },
    tableRowClicked(entity) {
      this.$router.push(entity.$routerLink);
    },
  },
  created() {
    TableHelper.rowClassHook((e) => (e.$routerLink ? "has-routerlink" : null));
    this.getUserDefinition();
    this.getEntities();
  },
};
</script>

<style lang="scss"></style>
