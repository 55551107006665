<template>
  <formular
    :isBusy="is.loading"
    :controlDefinition="controlDefinition"
    :controlValues="controlValues"
    inline
    :buttonVariants="{ submit: 'new' }"
    @formular:submit="onSubmit"
  >
    <template #submit><b-icon icon="send" /> {{ $t("components.entity.usertenants.inviteform.submit") }}</template>
  </formular>
</template>

<script>
import Formular from "@/components/form/Formular";

const INVITE_PROPERTIES = ["email", "language"];

export default {
  name: "UsertenantInviteForm",
  components: {
    Formular,
  },
  props: {
    parentId: {
      type: String,
      required: true,
    },
    entityKey: {
      type: String,
      required: true,
    },
    relationKey: {
      type: String,
      required: true,
    },
  },
  computed: {
    is() {
      return this.$store.getters[`${this.entityKey}/is`];
    },
    definition() {
      return this.$store.getters[`${this.entityKey}/definition`];
    },
    controlDefinition() {
      return Object.assign({}, this.definition, {
        properties: this.definition.properties.filter((p) => INVITE_PROPERTIES.includes(p.name)),
      });
    },
    controlValues() {
      return {
        language: this.$i18n.locale,
      };
    },
  },
  methods: {
    onSubmit(formData) {
      this.$store.dispatch(`${this.relationKey}/createInvitation`, {
        locationId: this.parentId,
        invite: formData.controls,
      });
    },
  },
};
</script>

<style lang="scss"></style>
