<template>
  <div class="module">
    <div class="module-title">
      <b-row align-v="center" align-h="between">
        <b-col cols="auto">
          <h3><slot name="title" /></h3>
        </b-col>

        <b-col cols="auto">
          <control-input
            :id="table.search.id"
            v-model="table.search.value"
            :placeholder="table.search.placeholder"
            size="sm"
            class="mb-0"
            @keydown.native.esc="resetTableSearch"
          >
            <template #append>
              <b-button variant="dark" :disabled="!table.search.value" @click="resetTableSearch"><b-icon icon="reset" /></b-button>
            </template>
          </control-input>
        </b-col>
      </b-row>
    </div>

    <b-table
      :id="table.id"
      :busy="is.loading || relationsIs.loading"
      :fields="tableData.fields"
      :items="tableData.items"
      :filter="table.search.value"
      :filter-included-fields="table.search.on"
      striped
      hover
      show-empty
      sort-icon-left
      :tbody-tr-class="table.helper.rowClass"
      @head-clicked="table.events.headClicked"
      @row-clicked="table.events.rowClicked"
      @row-contextmenu="table.events.rowContextMenu"
      @row-dblclicked="table.events.rowDblClicked"
      @row-middle-clicked="table.events.rowMiddleClicked"
      @row-hovered="table.events.rowHovered"
      @row-unhovered="table.events.rowUnhovered"
    >
      <template #table-busy>
        <div class="text-center">
          <loader inline />
          <div>{{ $t("components.table.busy") }}</div>
        </div>
      </template>

      <template #empty>
        <p class="my-2 text-center">{{ $t("components.table.empty") }}</p>
      </template>

      <template #emptyfiltered>
        <p class="my-2 text-center">{{ $t("components.table.emptyfiltered") }}</p>
      </template>

      <template #cell(permission)="data">
        <control-select
          :id="`${table.id}_Permission_${data.index}`"
          class="mb-0"
          size="sm"
          v-model="data.value.value"
          :options="data.value.options"
          :disabled="readOnly"
          @change="($value) => updateRelation(data.field.key, data.item, $value)"
        />
      </template>

      <template #cell($kill)="data" v-if="!readOnly">
        <b-button variant="warning" size="sm" class="text-nowrap" @click="openModal('kill', data.item)"
          ><b-icon icon="trash" /> <slot name="killButton"
        /></b-button>
      </template>

      <template #row-details="data">
        <template v-if="data.item.isTitle">
          <h3 class="h5">{{ data.item.title }}</h3>
        </template>
      </template>
    </b-table>

    <b-modal
      :id="confirmationModal.id"
      v-model="confirmationModal.show"
      size="md"
      centered
      scrollable
      no-fade
      header-bg-variant="warning"
      header-text-variant="black"
      title-tag="h4"
      title-class="align-self-center"
      hide-footer
    >
      <template #modal-header-close><b-icon icon="close" scale="0.6" /></template>
      <template #modal-title><slot name="modalTitle" :type="confirmationModal.type" /></template>

      <template v-if="confirmationModal.show">
        <div>
          <slot name="modalBody" :item="confirmationModal.item" :type="confirmationModal.type" />
        </div>

        <div class="d-flex justify-content-end mt-3">
          <div class="btn-list">
            <b-button variant="light" size="sm" @click="closeModal"><slot name="modalAbortButton" :type="confirmationModal.type" /></b-button>
            <b-button variant="danger" size="sm" @click="killRelation"
              ><b-icon icon="trash" /> <slot name="modalConfirmButton" :type="confirmationModal.type"
            /></b-button>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { valueFormatter } from "@/assets/js/helper/entity";
import Table, { TableHelper } from "@/assets/js/helper/table";

import Loader from "@/components/Loader";
import ControlInput from "@/components/form/ControlInput";
import ControlSelect from "@/components/form/ControlSelect";

export default {
  name: "Tenants.Users.RelationTable",
  components: {
    Loader,
    ControlInput,
    ControlSelect,
  },
  props: {
    entityKey: {
      type: String,
      required: true,
    },
    entityIdKey: {
      type: String,
      required: true,
    },
    relationKey: {
      type: String,
      required: true,
    },
    tableSettings: {
      type: Object,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const tableId = this.tableSettings.id || "Default";

    return {
      table: {
        id: `${this.$options.name}_${tableId}_Table`,
        helper: this.tableSettings.helper || TableHelper,
        filter: this.tableSettings.filter || ((entity) => true),
        mapper: this.tableSettings.mapper || ((entities, definition) => entities),
        search: Object.assign(
          {
            id: `${this.$options.name}_${tableId}_Table_Search`,
            value: "",
            on: null,
          },
          this.tableSettings.search || {}
        ),
        options: this.tableSettings.options || {},
        events: Object.assign(
          {
            headClicked: () => {},
            rowClicked: () => {},
            rowContextMenu: () => {},
            rowDblClicked: () => {},
            rowMiddleClicked: () => {},
            rowHovered: () => {},
            rowUnhovered: () => {},
          },
          this.tableSettings.events || {}
        ),
      },
      confirmationModal: {
        id: `${this.$options.name}_ConfirmationModal`,
        type: null,
        show: false,
        item: null,
      },
    };
  },
  computed: {
    relationsIs() {
      return this.$store.getters[`${this.relationKey}/is`];
    },
    relationsDefinition() {
      return this.$store.getters[`${this.relationKey}/definition`];
    },
    relations() {
      return this.$store.getters[`${this.relationKey}/getUnwrapped`];
    },
    entityIds() {
      return this.relations.map((r) => r[this.entityIdKey]);
    },
    is() {
      return this.$store.getters[`${this.entityKey}/is`];
    },
    definition() {
      return this.$store.getters[`${this.entityKey}/definition`];
    },
    entities() {
      return this.$store.getters[`${this.entityKey}/getEntitiesUnwrapped`](this.entityIds);
    },
    tableEntities() {
      const entitites = this.relations.filter(this.table.filter).map((RELATION) => {
        const ENTITY = this.entities.find((e) => e.id === RELATION[this.entityIdKey]) || {};

        const PERMISSION_DEFINITION = this.relationsDefinition.$properties.permission || {};
        const PERMISSION_OPTION_KEYS = (PERMISSION_DEFINITION.settings || {}).selectValues || [];
        const PERMISSION_TRANSLATIONS = ((PERMISSION_DEFINITION.translations || {})[this.$store.getters["gui/getLanguage"]] || {}).selectValues || [];

        return {
          _entity: ENTITY,
          _relation: RELATION,
          id: ENTITY.id,
          isAccepted: RELATION.isAccepted,
          // user
          email: ENTITY.email,
          permission: {
            value: RELATION.permission,
            options: PERMISSION_OPTION_KEYS.map((oKey) => ({ value: oKey, text: PERMISSION_TRANSLATIONS[oKey] })),
          },
        };
      });

      return this.table.mapper(entitites, this.definition);
    },
    tableData() {
      return new Table(this.tableEntities, this.table.options.fields);
    },
  },
  methods: {
    valueFormatter,
    setTableLabels() {
      if (this.definition.properties.length > 0 && this.relationsDefinition.properties.length > 0) {
        this.table.options.fields.label = Object.assign(
          this.table.options.fields.label,
          []
            .concat(this.definition.properties, this.relationsDefinition.properties)
            .reduce((labels, p) => Object.assign(labels, { [p.name]: p.translations[this.$store.getters["gui/getLanguage"]].name }), {})
        );
      }
    },
    resetTableSearch() {
      this.table.search.value = "";
    },
    updateRelation(key, item, value) {
      this.$store.dispatch(`${this.relationKey}/updateEntity`, Object.assign({}, item._relation, { [key]: value }));
    },
    declineRelation() {
      this.$store.dispatch(`${this.relationKey}/removeEntity`, { id: this.confirmationModal.item._relation.id });
      this.closeModal();
    },
    acceptRelation(item = {}) {
      this.$store.dispatch(`${this.relationKey}/updateEntity`, Object.assign({}, item._relation, { isAccepted: true }));
    },
    killRelation() {
      this.$store.dispatch(`${this.relationKey}/removeEntity`, { id: this.confirmationModal.item._relation.id });
      this.closeModal();
    },
    openModal(type = "", item = {}) {
      this.confirmationModal.type = type;
      this.confirmationModal.item = item;
      this.confirmationModal.show = true;
    },
    closeModal() {
      this.confirmationModal.type = null;
      this.confirmationModal.show = false;
      this.confirmationModal.item = null;
    },
  },
  created() {
    this.setTableLabels();
  },
  watch: {
    definition: {
      deep: true,
      handler() {
        this.setTableLabels();
      },
    },
    relationsDefinition: {
      deep: true,
      handler() {
        this.setTableLabels();
      },
    },
  },
};
</script>

<style lang="scss"></style>
